import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () =>
    import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  },
  // Admin Router
  {
    path: "/calendar",
    name: "Calendar",
    component: () =>
      import(/* webpackChunkName: "event" */ "../views/Calendar.vue"),
  },
  // App Router
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/Contact.vue"),
  },
  {
    path: "/adverts",
    name: "Advert",
    component: () =>
      import(/* webpackChunkName: "posts" */ "../views/advert/Advert.vue"),
  },
  {
    path: "/menu",
    name: "Menu",
    component: () => import(/* webpackChunkName: "menu" */ "../views/Menu.vue"),
  },
  {
    path: "/support",
    name: "Support",
    component: () =>
      import(/* webpackChunkName: "support" */ "../views/Support.vue"),
  },

  //Products
  {
    path: "/product",
    name: "Product",
    component: () =>
      import(/* webpackChunkName: "product" */ "../views/Product.vue"),
  },

  // School Gallery
  {
    path: "/gallery",
    name: "Gallery",
    component: () =>
    import(/* webpackChunkName: "gallery" */ "../views/Gallery.vue"),
  },
  {
    path: "/help",
    name: "Help",
    component: () => import(/* webpackChunkName: "help" */ "../views/Help.vue"),
  },
  {
    path: "/attachment",
    name: "Attachment",
    component: () =>
    import(/* webpackChunkName: "stationary" */ "../views/Attachment.vue"),
  },
  // MENUS
  {
    path: "/coffee",
    name: "CoffeeMenu",
    component: () =>
    import(/* webpackChunkName: "coffee" */ "../views/menus/CoffeeMenu.vue"),
  },
  {
    path: "/pastries",
    name: "PastriesMenu",
    component: () =>
    import(/* webpackChunkName: "pastries" */ "../views/menus/PastriesMenu.vue"),
  },
  {
    path: "/iced",
    name: "IcedMenu",
    component: () =>
    import(/* webpackChunkName: "iced" */ "../views/menus/IcedMenu.vue"),
  },
  {
    path: "/smoothies",
    name: "SmoothiesMenu",
    component: () =>
    import(/* webpackChunkName: "smoothies" */ "../views/menus/SmoothiesMenu.vue"),
  },

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Default scroll position will be 0, 0 unless overridden by a saved position
    const position = {
      x: 0,
      y: 0,
    };

    // Override default with saved position (if it exists)
    if (savedPosition) {
      position.x = savedPosition.x;
      position.y = savedPosition.y;
    }

    // Listen for scrollBeforeEnter event and set scroll position
    return new Promise((resolve) => {
      this.app.$root.$once("scrollBeforeEnter", () => {
        resolve(position);
      });
    });
  },
});

export default router;
