import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/css/styles.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./plugins/aos";
import vuetify from "./plugins/vuetify";
import "./registerServiceWorker";
import VueGtag from "vue-gtag";

Vue.use(
  VueGtag,
  {
    config: {
      id: "G-RKCX6S2X8B",
    },
    appName: "Brew Brothers Cafe PWA",
    pageTrackerScreenviewEnabled: true,
  },
  router
);

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
