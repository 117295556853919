<template>
  <v-app id="app">
    <Preload />
    <v-main>
      <router-view />
      <v-snackbar
        v-model="snackWithButtons"
        bottom
        color="#93691d"
        rounded="pill"
        outlined
        centered
        timeout="-1"
      >
        {{ snackWithBtnText }}
        <template v-slot:action="{ attrs }">
          <v-btn
            text
            rounded
            outlined
            color="#93691d"
            v-bind="attrs"
            @click.stop="refreshApp"
          >
            <v-icon left>mdi-refresh</v-icon>
            {{ snackBtnText }}
          </v-btn>
          <v-btn icon class="ml-4">
            <v-icon color="green">mdi-bell-ring-outline</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>
<script>
import Preload from "@/components/Preload";
export default {
  name: "App",
  data() {
    return {
      refreshing: false,
      registration: null,
      snackBtnText: "",
      snackWithBtnText: "",
      snackWithButtons: false,
    };
  },
  components: {
    Preload,
  },
  async created() {
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      });
    }
  },
  methods: {
    beforeEnter() {
      this.$root.$emit("scrollBeforeEnter");
    },
    showRefreshUI(e) {
      this.registration = e.detail;
      this.snackBtnText = "Refresh";
      this.snackWithBtnText = "New update installed";
      this.snackWithButtons = true;
    },
    refreshApp() {
      this.snackWithButtons = false;
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage("skipWaiting");
    },
  },
};
</script>