<template>
  <div class="text-center" v-if="overlay">
    <v-overlay :opacity="opacity" :value="overlay" color="white">
      <v-progress-circular
        color="#610518"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
export default {
  data: () => ({
    overlay: true,
    opacity: 2,
  }),
  mounted() {
    this.showToggle()
  },
  methods: {
    showToggle() {
      setTimeout(() => {
        this.overlay = false
      }, 1000)
    },
  },
}
</script>